import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useEventStore } from "store/useEventStore";

export default function EventNotFound() {
  const { theme, setEvent } = useEventStore();

  useEffect(() => {
    setEvent(null);
  }, [setEvent]);

  return (
    <div className="px-10 py-16 flex flex-col items-center">
      <h1 className="mb-9 text-theme-black text-center">
        <FormattedMessage
          id="title.eventNotFound"
          defaultMessage="Erro ao acessar o evento"
        />
      </h1>
      <div
        className={`flex flex-col items-center py-10 px-8 gap-2 card ${theme.card}`}
      >
        <p className="text-theme-black">
          <FormattedMessage
            id="subtitle.eventNotFound"
            defaultMessage="Evento não encontrado ou expirado."
          />
        </p>
      </div>
    </div>
  );
}
