import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useEventStore } from "store/useEventStore";

export default function UploadError() {
  const { theme } = useEventStore();
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className="px-10 py-16 flex flex-col items-center">
      <h1 className="mb-9 text-theme-black">
        <FormattedMessage id="title.recorder.error" defaultMessage="Erro!" />
      </h1>
      <div
        className={`flex flex-col items-center py-10 px-8 gap-2 card ${theme.card}`}
      >
        <p className="text-theme-black">
          <FormattedMessage
            id="subtitle.recorder.uploadError"
            defaultMessage="Erro ao fazer upload do vídeo."
          />
        </p>

        <p className="mb-8 text-theme-black">
          <FormattedMessage
            id="subtitle.recorder.tryAgain"
            defaultMessage="Verifique sua conexão e tente novamente."
          />
        </p>
        <button
          className={`py-3 px-10 ${theme.button}`}
          type="button"
          onClick={onClick}
        >
          <FormattedMessage
            id="button.recorder.recorder.back"
            defaultMessage="Voltar"
          />
        </button>
      </div>
    </div>
  );
}
