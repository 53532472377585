export default function ProgressBar({
  progress,
  color = "#15803d", // Default color green
}: {
  progress: number;
  color?: string;
}) {
  return (
    <div className="w-full h-2 bg-slate-200 rounded-full overflow-hidden">
      <div
        className={`h-full`}
        style={{
          width: `${progress}%`,
          backgroundColor: color,
        }}
      ></div>
    </div>
  );
}
