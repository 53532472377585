import { Share } from "assets/svg";
import { FormattedMessage } from "react-intl";
import { TTemplate } from "./Templates.types";
import { Link } from "react-router-dom";

export default function Birthday({
  bannerUrl,
  titleFontFamily,
  titleFontSize,
  titleOneText,
  titleOneShadowClass,
  subtitleText,
  wrapperClass,
  templateColor,
  language,
  onChangeLanguage,
  onShare,
  onCapsulaLandingPageClick,
  actionButtonLabel,
  onClick,
}: TTemplate) {
  return (
    <div
      className={`${wrapperClass} max-h-[100vh] template-container overflow-x-hidden flex flex-col items-center bg-white bg-contain bg-bottom bg-no-repeat bg-cover`}
    >
      <div className="relative birthday-banner">
        <img
          className="aspect-square object-cover w-full h-full"
          src={bannerUrl}
          alt="banner-home"
        />
      </div>
      <div className="px-5 relative z-10 mt-8">
        <h6
          data-shadow={titleOneText}
          className={`${titleFontFamily} ${titleOneShadowClass} text-theme-black font-normal text-center relative before:content-[attr(data-shadow)] before:absolute before:z-[-1] before:top-[-0.25em] before:left-[-0.5em] before:text-[1.5em] before:opacity-[0.2] before:whitespace-nowrap`}
          style={{
            fontSize: titleFontSize,
          }}
          translate="no"
        >
          {titleOneText}
        </h6>
      </div>
      <div className="flex flex-col items-center px-[60px] mt-2 mb-7">
        <p className="font-century-gothic text-sm">{subtitleText}</p>
      </div>
      <div className="flex items-center justify-center gap-4 px-4 mb-8">
        <button
          onClick={onChangeLanguage}
          type="button"
          className={`${templateColor} shadow-md shadow-gray-300 font-century-gothic-bold text-sm rounded-full text-theme-black p-3 h-10 w-10 flex justify-center items-center`}
          data-testid="change-language"
        >
          {language}
        </button>
        <button
          onClick={onClick}
          type="button"
          className={`${templateColor} shadow-md shadow-gray-300 font-century-gothic-bold text-sm rounded-[10px] text-theme-black h-10 px-10 flex-auto flex whitespace-nowrap justify-center items-center`}
        >
          {actionButtonLabel}
        </button>
        <button
          onClick={onShare}
          type="button"
          className={`${templateColor} shadow-md shadow-gray-300 font-century-gothic-bold text-sm rounded-full text-theme-black p-3 h-10 w-10 flex justify-center items-center`}
        >
          <Share width={20} height={20} outline="#272727" color="#272727" />
        </button>
      </div>
      <div className="text-terms bg-white px-2">
        <FormattedMessage
          id="message.termsOfUse"
          defaultMessage="Ao utilizar nosso sistema você concorda com nossos <code>Termos de Uso</code>"
          values={{
            code: (text) => (
              <Link to="/terms" className="underline text-terms">
                {text}
              </Link>
            ),
          }}
        />
      </div>
    </div>
  );
}
