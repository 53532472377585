import { FormattedMessage } from "react-intl";
import { useEventStore } from "store/useEventStore";

export default function ServiceTerms() {
  const { theme } = useEventStore();

  return (
    <div className="template-container px-10 py-16 flex flex-col items-center">
      <h1 className="mb-9 text-theme-black text-center">
        <FormattedMessage id="title.terms" defaultMessage="Termos de Uso" />
      </h1>
      <div
        className={`flex flex-col items-center py-10 px-8 gap-2 card ${theme.card}`}
      >
        <p className="text-theme-black">
          <FormattedMessage
            id="message.termsFirstParagraph"
            defaultMessage="Ao usar nosso sistema de envio de fotos e vídeos, você, como convidado do evento, concorda que coletamos e enviamos seus dados, incluindo  fotos, vídeos e informações pessoais, e disponibilize para o anfitrião do evento."
          />
        </p>
        <p className="text-theme-black mt-4">
          <FormattedMessage
            id="message.termsSecondParagraph"
            defaultMessage="Nós não acessamos seus arquivos, a menos que seja solicitado pelo cliente para fornecer suporte técnico, limitando-se ao necessário."
          />
        </p>
        <p className="text-theme-black mt-4">
          <FormattedMessage
            id="message.termsThirdParagraph"
            defaultMessage="A empresa não compartilha os dados com terceiros e mantém a confidencialidade das informações, exceto quando exigido por lei."
          />
        </p>
      </div>
    </div>
  );
}
