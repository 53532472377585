import { create } from "zustand";
import { TUploadHistoryStore } from "./Store.types";
import { createJSONStorage, persist } from "zustand/middleware";

export const useUploadHistoryStore = create<TUploadHistoryStore>()(
  persist(
    (set) => ({
      uploadHistory: {},
      setUploadHistory: (eventId: string, history: any) => {
        set((state) => {
          const eventHistory = state.uploadHistory[eventId] || [];
          const newEntry = {
            ...history,
            date: new Date().toISOString(),
          };

          return {
            uploadHistory: {
              [eventId]: [...eventHistory, newEntry],
            },
          };
        });
      },
    }),
    {
      name: "upload-history-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
