import { useEventStore } from "store/useEventStore";

export default function LanguageButton({
  label,
  onChange,
}: {
  label: string;
  onChange: any;
}) {
  const { theme } = useEventStore();

  if (theme.name === "white") {
    return (
      <button
        onClick={onChange}
        type="button"
        className="shadow square-white bg-white text-theme-black p-3 h-10 w-10 flex justify-center items-center"
        data-testid="change-language"
      >
        {label}
      </button>
    );
  }

  return (
    <button
      type="button"
      className="rounded-full bg-transparent border border-primary-rose text-primary-rose p-3 h-10 w-10 flex justify-center items-center"
      onClick={onChange}
      data-testid="change-language"
    >
      {label}
    </button>
  );
}
