import { Share } from "assets/svg";
import { useEventStore } from "store/useEventStore";

export default function ShareButton({
  onShare,
}: {
  onShare: React.MouseEventHandler;
}) {
  const { theme } = useEventStore();

  if (theme.name === "white") {
    return (
      <button
        onClick={onShare}
        type="button"
        className="shadow square-white bg-white text-theme-black p-3 h-10 w-10 flex justify-center items-center"
      >
        <Share width={20} height={20} outline="#272727" color="#272727" />
      </button>
    );
  }
  return (
    <button
      onClick={onShare}
      type="button"
      className="rounded-full bg-transparent border border-primary-rose text-primary-rose p-2.5 h-10 w-10 flex justify-center items-center"
    >
      <Share width={20} height={20} outline="#F76C6F" color="#F76C6F" />
    </button>
  );
}
