import React from "react";
import { Logo as LogoImg } from "assets/svg";

type LogoProps = {
  color?: string;
  height?: string;
  width?: string;
};

export default function Logo({
  color = "#F76C6F",
  height = "31px",
  width = "88px",
}: LogoProps) {
  return (
    <div className="flex flex-col items-center justify-center">
      <LogoImg outline={color} height={height} width={width} color={color} />
    </div>
  );
}
