import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AppLayout from "layouts/AppLayout";
import Home from "pages/Home";
import Loading from "components/Loading";
import NotFound from "pages/NotFound";
import EventNotFound from "pages/EventNotFound";
import Upload from "pages/Upload";
import ServiceTerms from "pages/ServiceTerms";
import { eventLoader } from "services/events";
import Guide from "pages/recorder/Guide";
import Record from "pages/recorder/Record";
import Share from "pages/Share";

export default function AppRouter() {
  const router = createBrowserRouter([
    {
      element: <AppLayout />,
      loader: eventLoader,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/record",
          children: [
            {
              path: "guide",
              element: <Guide />,
            },
            {
              path: ":facingMode",
              element: <Record />,
            },
          ],
        },
        {
          path: "/upload",
          element: <Upload />,
        },
        {
          path: "/event-not-found",
          element: <EventNotFound />,
        },
        {
          path: "/share",
          element: <Share />,
        },
        {
          path: "/terms",
          element: <ServiceTerms />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<Loading />} />;
}
