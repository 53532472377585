import { FormattedMessage } from "react-intl";
import iOSSafariSiteSettings from "assets/images/ios-safari-site-settings.png";
import iOSSafariSitePermissions from "assets/images/ios-safari-site-permissions.png";

export default function IOSInstructions() {
  return (
    <>
      <p className="font-century-gothic-bold">
        <FormattedMessage
          id="subtitle.recorder.permissionCameraOrMicrophoneDenied"
          defaultMessage="Não foi permitido o uso da câmera/microfone"
        />
      </p>
      <p className="">
        <FormattedMessage
          id="subtitle.recorder.trySafariBrowser"
          defaultMessage="1. Use o navegador SAFARI"
          values={{
            code: (text) => (
              <span className="font-century-gothic-bold">{text}</span>
            ),
          }}
        />
      </p>
      <p className="">
        <FormattedMessage
          id="subtitle.recorder.changeSiteSettingsUsingSafari"
          defaultMessage='2. Clique no botão <code>"aA"</code> na barra da URL e em <code>"Ajustes do site"</code>'
          values={{
            code: (text) => (
              <span className="font-century-gothic-bold">{text}</span>
            ),
          }}
        />
      </p>

      <img src={iOSSafariSiteSettings} alt="Configurações da página" />

      <p className="">
        <FormattedMessage
          id="subtitle.recorder.grantCameraAndMicrophonePermissions"
          defaultMessage="3. <code>Permita o uso</code> da câmera e microfone"
          values={{
            code: (text) => (
              <span className="font-century-gothic-bold">{text}</span>
            ),
          }}
        />
      </p>

      <img src={iOSSafariSitePermissions} alt="Permissões da página" />

      <p className="">
        <FormattedMessage
          id="subtitle.recorder.refreshPage"
          defaultMessage="4. Atualize a página"
        />
      </p>
    </>
  );
}
