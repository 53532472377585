import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useEventStore } from "store/useEventStore";
import IOSInstructions from "./PermissionCameraOrMicrophoneDeniedError/IOSInstructions";
import AndroidInstructions from "./PermissionCameraOrMicrophoneDeniedError/AndroidInstructions";
import { getBrowser } from "../../../hooks/useLogEvent";

export default function PermissionCameraOrMicrophoneDeniedError() {
  const { theme } = useEventStore();
  const navigate = useNavigate();
  const { os } = getBrowser();
  const isIOS = os === "ios";

  const onClick = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className="template-container px-10 py-16 flex flex-col items-center">
      <h1 className="mb-9 text-theme-black text-center">
        <FormattedMessage
          id="title.recorder.permissionCameraOrMicrophoneDenied"
          defaultMessage="Permissão não concedida"
        />
      </h1>

      <div
        className={`flex flex-col items-center py-10 px-8 gap-8 card ${theme.card}`}
      >
        {isIOS ? <IOSInstructions /> : <AndroidInstructions />}
      </div>

      <div className="mt-16">
        <button
          className={`py-3 px-10 ${theme.button}`}
          type="button"
          onClick={onClick}
        >
          <FormattedMessage id="button.recorder.back" defaultMessage="Voltar" />
        </button>
      </div>
    </div>
  );
}
