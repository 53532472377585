import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { FormattedMessage } from "react-intl";
import { useEventStore } from "store/useEventStore";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function TimeErrorModal({ isOpen, onClose }: Props) {
  const { theme } = useEventStore();
  const [countDown, setCountDown] = React.useState(5);

  useEffect(() => {
    if (!isOpen) return;
    if (countDown <= 0) {
      onClose();
    }
  }, [countDown, onClose, isOpen]);

  useEffect(() => {
    if (!isOpen) return;
    const timer = setTimeout(() => {
      setCountDown((prevCount) => {
        return prevCount - 1;
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [countDown, onClose, isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      id="time-error-modal-overlay"
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-10"
    >
      <div
        id="time-error-modal-wrapper"
        className={`px-6 py-10 shadow-xl flex flex-col items-center card ${theme.card}`}
      >
        <h1 className="mb-9 text-theme-black">
          <FormattedMessage
            id="title.recorder.alert"
            defaultMessage="Alerta!"
          />
        </h1>
        <p className="mb-8 text-theme-black">
          <FormattedMessage
            id="subtitle.recorder.mustLongerThan"
            values={{
              code: (text) => <span className="highlight">{text}</span>,
            }}
            defaultMessage={`A mensagem deve ter uma duração maior que 5 segundos.`}
          />
        </p>
        <button
          className={`py-3 px-10 ${theme.button}`}
          type="button"
          onClick={onClose}
        >
          <FormattedMessage
            id="button.recorder.close"
            defaultMessage="Fechar"
          />{" "}
          ({countDown})
        </button>
      </div>
    </div>,
    document.body
  );
}
