import Logo from "components/Logo";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="pt-40 bg-theme-black">
      <Logo color="#ffffff" height="150px" width="70vw" />
      <h6 className="text-white text-2xl text-center font-playfair-display font-normal mt-8">
        [404] -{" "}
        <FormattedMessage
          id="title.notFound"
          defaultMessage="Ops, parece que essa página não existe."
        />
      </h6>
      <h2 className="text-white mt-20">
        <FormattedMessage
          id="subtitle.goBackTo"
          defaultMessage="Voltar para a"
        />{" "}
        <Link
          to="/"
          state={{ from: "/" }}
          replace={true}
          className="text-white"
        >
          Home
        </Link>
      </h2>
    </div>
  );
}
