import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useEventStore } from "store/useEventStore";
import chrome from "assets/images/chrome.jpg";
import opera from "assets/images/opera.jpg";
import iphone from "assets/images/iphone.png";
import samsung from "assets/images/samsung.jpg";
import desktopChrome from "assets/images/desktop-chrome.png";
import desktopSafari from "assets/images/desktop-safari.png";
import { useLogEvent } from "hooks/useLogEvent";

export default function Guide() {
  const { theme } = useEventStore();
  const navigate = useNavigate();
  const { logGuide } = useLogEvent();

  const onClick = () => {
    logGuide();
    // set default facingMode to user
    navigate("/record/user", { replace: true });
  };

  const imageSrc = useMemo(() => {
    const userAgent = navigator.userAgent;

    // check OS
    if (userAgent.match(/android/i)) {
      //   os = "android";
      if (userAgent.match(/opr\//i)) {
        // browserName = "opera";
        return opera;
      } else if (userAgent.match(/samsungbrowser\//i)) {
        // browserName = "samsung";
        return samsung;
      } else {
        // browserName = "chrome";
        return chrome;
      }
    } else if (userAgent.match(/iphone|ipad/i)) {
      //   os = "ios";
      return iphone;
    } else {
      //   os = "desktop";
      if (userAgent.match(/chrome|chromium|crios/i)) {
        // browserName = "chrome";
        return desktopChrome;
      } else if (userAgent.match(/safari\//i)) {
        // browserName = "safari";
        return desktopSafari;
      }
      return desktopChrome;
    }
  }, []);

  return (
    <div className="px-10 py-16 flex flex-col items-center">
      <h1 className="mb-9 text-theme-black text-center">
        <FormattedMessage
          id="title.recorder.permissionsToRecordAudioVideo"
          defaultMessage="Permissão para Gravar"
        />
      </h1>
      <div
        className={`flex flex-col items-center pt-10 pb-8 px-8 mb-8 gap-5 card ${theme.card}`}
      >
        <p className="text-theme-black">
          <FormattedMessage
            id="subtitle.recorder.nextScreenClickAllow"
            values={{
              code: (text) => <strong>{text}</strong>,
            }}
            defaultMessage={`Na próxima tela, clique em "Permitir"`}
          />
        </p>
        <img src={imageSrc} alt="allow access" />
        <p className="text-theme-black">
          <FormattedMessage
            id="subtitle.recorder.allowReinforcement"
            values={{
              code: (text) => <strong>{text}</strong>,
            }}
            defaultMessage={`É necessário que você clique em "Permitir" para gravar sua mensagem.`}
          />
        </p>
      </div>
      <button
        className={`py-3 px-10 ${theme.button}`}
        type="button"
        onClick={onClick}
      >
        <FormattedMessage
          id="button.recorder.understood"
          defaultMessage="Entendi!"
        />
      </button>
    </div>
  );
}
