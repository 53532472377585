export const dateToString = (date: Date) => {
  //if date is not a Date, return date
  if (!(date instanceof Date)) return date;

  if (date) {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);

    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const resultDay = day < 10 ? `0${day}` : day;
    const resultMonth = month < 10 ? `0${month}` : month;

    return `${year}-${resultMonth}-${resultDay}`;
  }
  return "";
};

export const selectLanguage = (
  languages: string[] | undefined,
  language: string
) => {
  if (!languages) return null;

  const index = languages.indexOf(language);
  if (index === undefined) {
    return null;
  }
  const nextIndex = index + 1;
  return languages[nextIndex] || languages[0];
};

export const parseLanguage = (
  languages: string[] | undefined,
  language: string
) => {
  if (!languages) return null;

  const index = languages.indexOf(language);

  if (index === undefined) {
    return null;
  }

  const nextIndex = index + 1;
  const nextLanguage = languages[nextIndex] || languages[0];
  const nextLanguageParser = nextLanguage.split("-")?.[0]?.toUpperCase();

  return nextLanguageParser as string;
};

export const shareLink = async (url: any, text: any, logCallback: Function) => {
  console.log("text", text);

  if (navigator.share === undefined) {
    console.debug("navigator share support not found");
    return;
  }

  await navigator.share({ url, text });

  logCallback();
};
