import AppRouter from "./routers/AppRouter";
import { clarity } from "react-microsoft-clarity";

function App() {
  if (process.env.NODE_ENV === "production") {
    clarity.init("mx2hn3ag4n");
  }

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
