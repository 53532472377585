import en_US from "./en_US";
import pt_BR from "./pt_BR";
import fr_FR from "./fr_FR";
import es_ES from "./es_ES";
import vi_VN from "./vi_VN";

const i18n = {
  "en-US": en_US,
  "pt-BR": pt_BR,
  "fr-FR": fr_FR,
  "es-ES": es_ES,
  "vi-VN": vi_VN,
};

export default i18n;
