export default function BigButton({
  label,
  icon,
  className,
  onClick,
}: {
  label: String | React.ReactNode;
  icon: React.ReactNode;
  className?: String;
  onClick: React.MouseEventHandler;
}) {
  return (
    <button
      type="button"
      className={`
        font-century-gothic-bold text-base text-white py-3 w-[120px] flex whitespace-nowrap justify-center items-center my-0 mx-auto rounded-xl flex flex-col ${className}
      `}
      onClick={onClick}
    >
      {icon && <div className="mb-2">{icon}</div>}

      {label}
    </button>
  );
}
