import { TEvent } from "services/types";
import { useEffect } from "react";
import { Outlet, useLoaderData } from "react-router-dom";
import { IntlProvider } from "react-intl";
import i18n from "i18n";
import { useEventStore } from "store/useEventStore";
import EventNotFound from "pages/EventNotFound";

export default function AppLayout() {
  const event = useLoaderData() as TEvent;

  const { setEvent, event: storeEvent, theme, language } = useEventStore();

  useEffect(() => {
    setEvent(event);
  }, [event, setEvent]);

  return (
    <IntlProvider
      messages={i18n[language as keyof typeof i18n]}
      locale={language}
      defaultLocale="en-US"
    >
      <div className={`theme-layout ${theme.background}`}>
        {storeEvent ? <Outlet /> : <EventNotFound />}
      </div>
    </IntlProvider>
  );
}
