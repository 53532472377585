import { useLogEvent } from "hooks/useLogEvent";
import React, { useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useEventStore } from "store/useEventStore";

export default function Success() {
  const { theme } = useEventStore();
  const navigate = useNavigate();
  const [countDown, setCountDown] = React.useState(5);
  const { logViewSuccessPage } = useLogEvent();

  const onTimeIsUp = useCallback(() => {
    logViewSuccessPage();
    navigate("/share", { replace: true });
  }, [navigate, logViewSuccessPage]);

  useEffect(() => {
    if (countDown <= 0) {
      onTimeIsUp();
    }
  }, [countDown, onTimeIsUp]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountDown((prevCount) => prevCount - 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [countDown]);

  return (
    <div className="px-10 py-16 flex flex-col items-center">
      <h1 className="mb-9 text-theme-black">
        <FormattedMessage
          id="title.recorder.success"
          defaultMessage="Sucesso!"
        />
      </h1>
      <div
        className={`flex flex-col items-center py-10 px-8 card ${theme.card}`}
      >
        <p className="mb-8 text-theme-black">
          <FormattedMessage
            id="subtitle.recorder.status.success"
            defaultMessage="Sua mensagem foi gravada com sucesso!"
          />
        </p>
        <button
          className={`py-3 px-10 ${theme.button}`}
          type="button"
          onClick={onTimeIsUp}
        >
          <FormattedMessage
            id="button.recorder.close"
            defaultMessage="Fechar"
          />{" "}
          ({countDown})
        </button>
      </div>
    </div>
  );
}
