import React, { useEffect } from "react";
import Logo from "./Logo";
import { useLogEvent } from "hooks/useLogEvent";

export default function Loading() {
  const { logLogin } = useLogEvent();

  useEffect(() => {
    logLogin();
  }, [logLogin]);

  return (
    <div className="pt-40 animate-pulse">
      <Logo width="70vw" height="150px" color="#F76C6F" />
    </div>
  );
}
