/**
 * Name: VideoRecorder.tsx
 * Description: Component to record a video
 * Happy path:
 * 1. _startMediaStream
 * - component renders
 * - user clicks the record button
 * 2. _startDebouncing
 * 3. startRecording
 * - component renders because action changed to `record`
 * - user clicks the stop button after 5 seconds
 * 4. stopRecording
 * 5. _onDataAvailable
 * 6. _onStop
 * 7. _uploadVideo
 * - component renders because action changed to `show-upload`
 * - component renders because of uploadProgress getting updated
 * - component renders because action changed to `show-success`
 */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  MediaAudioTrackConstraints,
  MediaVideoTrackConstraints,
} from "./VideoRecorder.types";
import { useUploader } from "services/file";
import TimeErrorModal from "./components/TimeErrorModal";
import UploadProgress from "./components/UploadProgress";
import UploadError from "./components/UploadError";
import Success from "./components/Success";
import BrowserError from "./components/BrowserError";
import { FormattedMessage } from "react-intl";
import { useNavigatorOnline } from "hooks/useNavigatorOnline";
import { RefreshRight, WifiOff } from "assets/svg";
import { useLogEvent } from "hooks/useLogEvent";
import { useParams } from "react-router-dom";
import PermissionCameraOrMicrophoneDeniedError from "./components/PermissionCameraOrMicrophoneDeniedError";

export default function VideoRecorder() {
  const [action, setAction] = React.useState<
    | "preview"
    | "record"
    | "show-upload"
    | "show-success"
    | "time-error"
    | "permission-camera-or-microphone-denied"
    | "browser-error"
    | "upload-error"
  >("preview"); // this will trigger a re-render
  const { facingMode } = useParams();
  const status = React.useRef<
    "live" | "debouncing" | "recording" | "uploading" | "success"
  >("live"); // this will not trigger a re-render
  const [uploadProgress, setUploadProgress] = React.useState<number>(-1);
  const mediaStreamRef = React.useRef<MediaStream | null>(null);
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const mediaRecorderRef = React.useRef<MediaRecorder | null>(null);
  const recordingBlobRef = React.useRef<Blob[]>([]);
  const isThumbnailUploaded = React.useRef<boolean>(false);
  const debouncingTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const fileExtensionRef = React.useRef<string | null>(null);

  const { uploadFile } = useUploader();

  const {
    logStartRecording,
    logStopRecordingDuringDebounce,
    logStopRecording,
    logBrowserNotSupported,
    logCameraMicrophonePermissionsDenied,
  } = useLogEvent();

  const videoConstraints: MediaVideoTrackConstraints = useMemo(
    () => ({
      width: { ideal: 1280, min: 720, max: 1920 },
      height: { ideal: 1280, min: 720, max: 1920 },
      facingMode: facingMode,
      deviceId: "default",
      frameRate: 30,
    }),
    [facingMode]
  );

  const audioConstraints: MediaAudioTrackConstraints = useMemo(
    () => ({
      deviceId: "default",
      groupId: "default",
    }),
    []
  );

  // function to get the media recorder options according to the browser support
  const mediaRecorderOptions: MediaRecorderOptions | undefined = useMemo(() => {
    const options = [
      {
        // VP9 in WebM: Excellent quality and compression, but less browser support
        mimeType: "video/webm;codecs=vp9,opus",
        fileExtension: "webm",
      },
      {
        // H.265/HEVC in MP4: High quality, good compression, less compatibility
        mimeType: "video/mp4;codecs=hvc1,mp4a.40.2",
        fileExtension: "mp4",
      },
      {
        // H.264 in MP4: Good balance of quality and compatibility
        mimeType: "video/mp4;codecs=avc1.42E01E,mp4a.40.2",
        fileExtension: "mp4",
      },
      {
        // VP8 in WebM: Good quality, wider compatibility
        mimeType: "video/webm;codecs=vp8,opus",
        fileExtension: "webm",
      },
      {
        // A fallback option when specific codecs for WebM aren't supported, with variable quality and compression.
        mimeType: "video/webm",
        fileExtension: "webm",
      },
      {
        // The last resort, ensuring maximum compatibility even if specific codecs or WebM formats are unsupported.
        mimeType: "video/mp4",
        fileExtension: "mp4",
      },
    ];

    for (const option of options) {
      if (MediaRecorder.isTypeSupported(option.mimeType)) {
        fileExtensionRef.current = option.fileExtension;
        return { mimeType: option.mimeType };
      }
    }
    setAction("browser-error");
    logBrowserNotSupported();
    return undefined;
  }, [logBrowserNotSupported]);

  // === HELPER FUNCTIONS ===

  /**
   * Name: _startMediaStream
   * Description: function to start the media stream that is going to be used to record the video and to show the preview
   * @param videoConstraints: MediaVideoTrackConstraints
   * @param audioConstraints: MediaAudioTrackConstraints
   * @modifies mediaStreamRef
   * @modifies videoRef
   * @modifies action
   * @returns void
   */
  const _startMediaStream = useCallback(async () => {
    console.log("1. _startMediaStream");
    try {
      if (!window.MediaRecorder) {
        throw new Error("MediaRecorder is not supported");
      }
      const stream = await window.navigator.mediaDevices.getUserMedia({
        audio: audioConstraints,
        video: videoConstraints,
      });

      // for iphones / safari - this keeps the video with the correct width and height
      stream.getVideoTracks()[0].applyConstraints({
        width: 1000,
        facingMode: facingMode,
        frameRate: 30,
      });

      const c = stream.getVideoTracks()[0].getConstraints();
      console.log("video constraints: ", c);

      mediaStreamRef.current = stream;
      videoRef.current!.srcObject = stream;
    } catch (err: any) {
      console.log(err.name, err.message);
      if (
        err.name === "NotReadableError" &&
        err.message === "Could not start video source"
      ) {
        console.error("ignoring Error._startMediaStream: ", err);
      } else if (err.name === "NotAllowedError") {
        setAction("permission-camera-or-microphone-denied");
        logCameraMicrophonePermissionsDenied();
      } else {
        setAction("browser-error");
        logBrowserNotSupported();
        console.log("Error._startMediaStream: ", err);
      }
    }
  }, [
    videoConstraints,
    audioConstraints,
    logBrowserNotSupported,
    logCameraMicrophonePermissionsDenied,
    facingMode,
  ]);

  /**
   * Name: _startDebouncing
   * Description: function to start the debouncing timeout (e.g. when the user clicks the record button and clicks again to stop the recording before the debouncing timeout ends - 5 seconds)
   * @param status: string
   * @modifies status
   * @modifies debouncingTimeoutRef
   * @returns void
   */
  const _startDebouncing = useCallback(() => {
    status.current = "debouncing";
    console.log("2. _startDebouncing");
    debouncingTimeoutRef.current = setTimeout(() => {
      if (status.current === "debouncing") {
        status.current = "recording";
      }
    }, 5000);
  }, []);

  /**
   * Name: _onDataAvailable
   * Description: function to pass the recorded data to the recordingBlobRef when the recording is stopped
   * @param data: BlobEvent
   * @modifies recordingBlobRef
   * @returns void
   */
  const _onDataAvailable = useCallback(({ data }: BlobEvent) => {
    console.log("6. data._onDataAvailable: ", data);
    if (data && data.size > 0) {
      recordingBlobRef.current?.push(data);
    }
  }, []);

  /**
   * Name: _uploadVideo
   * Description: function to upload the video to the server
   * @async
   * @param file: File
   * @param status: string
   * @modifies status
   * @modifies action
   * @modifies uploadProgress
   * @await uploadVideo
   * @returns void
   */
  const _uploadVideo = useCallback(
    async (file: File) => {
      console.log("8. _uploadVideo");
      if (file === null) return;
      status.current = "uploading";
      setAction("show-upload");
      try {
        await uploadFile(file, setUploadProgress);
        status.current = "success";
        setAction("show-success");
      } catch (error) {
        console.error("Error._uploadVideo: ", error);
        status.current = "live";
        setAction("upload-error");
      }
    },
    [uploadFile]
  );

  /**
   * Name: _onStop
   * Description: function called when the recording is stopped. It checks if the recording time is greater than 5 seconds and if it is, it calls the uploadVideo function
   * @param mediaRecorderRef: MediaRecorder
   * @param status: string
   * @modifies recordingBlobRef
   * @modifies mediaRecorderRef
   * @modifies action
   * @see _uploadVideo
   * @returns void
   */
  const _onStop = useCallback(() => {
    console.log("7. _onStop");
    console.log("mediaRecorderRef._onStop: ", mediaRecorderRef.current);
    console.log("status._onStop: ", status.current);
    if (mediaRecorderRef.current === null) return;
    if (status.current === "recording") {
      const fileName = `temporary-video.${fileExtensionRef.current}`;

      const file = new File(recordingBlobRef.current, fileName, {
        type: `video/${fileExtensionRef.current}`,
        lastModified: Date.now(),
      });

      _uploadVideo(file);

      recordingBlobRef.current = [];
      mediaRecorderRef.current = null;
      logStopRecording();
    } else {
      logStopRecordingDuringDebounce();
      setAction("time-error");
    }
  }, [_uploadVideo, logStopRecordingDuringDebounce, logStopRecording]);

  // === MAIN FUNCTIONS ===

  /**
   * Name: startRecording
   * Description: function to start the recording
   * @param mediaStreamRef: MediaStream
   * @param mediaRecorderOptions: MediaRecorderOptions
   * @modifies mediaRecorderRef
   * @modifies action
   * @see _startDebouncing
   * @see _onDataAvailable
   * @see _onStop
   * @returns void
   */
  const startRecording: () => void = useCallback(() => {
    if (mediaStreamRef.current === null) return;
    _startDebouncing();
    console.log("3. startRecording");
    setAction("record");
    logStartRecording();
    try {
      mediaRecorderRef.current = new MediaRecorder(
        mediaStreamRef.current,
        mediaRecorderOptions
      );
      mediaRecorderRef.current.ondataavailable = _onDataAvailable;
      mediaRecorderRef.current.onstop = _onStop;
      mediaRecorderRef.current.start();
    } catch (error) {}
  }, [
    mediaRecorderOptions,
    _onDataAvailable,
    _startDebouncing,
    _onStop,
    logStartRecording,
  ]);

  /**
   * Name: stopRecording
   * Description: function to stop the recording. This can be to start the upload process or to reset the recording (if the recording time is less than 5 seconds)
   * @param mediaRecorderRef: MediaRecorder
   * @modifies mediaRecorderRef
   * @modifies isThumbnailUploaded
   * @modifies debouncingTimeoutRef
   * @returns void
   */
  const stopRecording: () => void = useCallback(() => {
    console.log("5. stopRecording");
    if (mediaRecorderRef.current === null) return;
    if (mediaRecorderRef.current.state === "inactive") return;
    mediaRecorderRef.current.stop();
    isThumbnailUploaded.current = false;
    clearTimeout(debouncingTimeoutRef.current!);
  }, []);

  /**
   * Name: resetRecording
   * Description: function to reset the recording (if the recording time is less than 5 seconds)
   * @modifies action
   * @modifies isThumbnailUploaded
   * @modifies debouncingTimeoutRef
   * @returns void
   */
  const resetRecording: () => void = useCallback(() => {
    console.log("resetRecording");
    setAction("preview");
    isThumbnailUploaded.current = false;
    clearTimeout(debouncingTimeoutRef.current!);
  }, []);

  const toggleFacingMode = useCallback(() => {
    // reload page
    const newFacingMode = facingMode === "user" ? "environment" : "user";
    window.location.pathname = `/record/${newFacingMode}`;
  }, [facingMode]);

  // === EFFECTS ===

  // how many times this component is rendering?
  useEffect(() => {
    console.log("VideoRecorder rendered");
  });

  // how many times this component is rendering when the status changes?
  useEffect(() => {
    console.log("Rendered by status: ", action);
    console.log("status: ", status.current);
  }, [action]);

  /**
   * Name: useEffect to start the media stream
   * Description: function to start the media stream when the component renders
   * @modifies mediaStreamRef
   * @see _startMediaStream
   */
  useEffect(() => {
    if (mediaStreamRef.current === null || facingMode) {
      _startMediaStream();
    }
  }, [_startMediaStream, facingMode]);

  /**
   * Name: useEffect cleanup
   * Description: function to cleanup the component
   * @modifies debouncingTimeoutRef
   * @modifies mediaRecorderRef
   */
  useEffect(() => {
    return () => {
      if (debouncingTimeoutRef.current !== null) {
        clearTimeout(debouncingTimeoutRef.current);
      }
      if (mediaRecorderRef.current !== null) {
        mediaRecorderRef.current?.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  if (action === "show-success") {
    return <Success />;
  }

  if (action === "show-upload") {
    return <UploadProgress progress={uploadProgress} />;
  }

  if (action === "permission-camera-or-microphone-denied") {
    return <PermissionCameraOrMicrophoneDeniedError />;
  }

  if (action === "browser-error") {
    return <BrowserError />;
  }

  if (action === "upload-error") {
    return <UploadError />;
  }

  return (
    <div
      className="h-full grid"
      style={{
        gridTemplateRows: "auto",
        gridTemplateColumns: "auto",
      }}
    >
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        className={`my-0 mx-auto object-cover fixed inset-0 w-full h-full ${
          facingMode === "user" && "-scale-x-100"
        }`}
      />
      {action === "record" && <TimerComponent onTimeIsUp={stopRecording} />}

      {/* components placed in the bottom */}
      <div className="fixed w-full bottom-8 mx-auto flex flex-col justify-center items-center gap-2">
        {action === "preview" && (
          <div className="w-full grid grid-cols-[1fr,64px,1fr] items-center">
            <div />
            <StartButtonComponent start={startRecording} />
            <ReverseButtonComponent reverse={toggleFacingMode} />
          </div>
        )}
        {action === "record" && <StopButtonComponent stop={stopRecording} />}
      </div>
      <TimeErrorModal
        isOpen={action === "time-error"}
        onClose={resetRecording}
      />
    </div>
  );
}

type TimerComponentProps = {
  onTimeIsUp: () => void;
};

const TimerComponent = ({ onTimeIsUp }: TimerComponentProps) => {
  const [timeLeft, setTimeLeft] = useState(60);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((timeLeft) => {
        if (timeLeft <= 0) {
          clearInterval(intervalId);
          onTimeIsUp();
          return 0;
        }
        return timeLeft - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, onTimeIsUp]);

  return (
    <div className="fixed top-0 w-full pt-6 px-6 flex items-center justify-between animate-pulse">
      <div className="flex items-center gap-1">
        <span className="h-4 w-4 rounded-full bg-red-600"></span>
        <p className="font-century-gothic-bold text-white text-xl">
          <FormattedMessage
            id="subtitle.recorder.recording"
            defaultMessage={`Gravando...`}
          />
        </p>
      </div>
      <div>
        <p className="font-century-gothic-bold text-white text-xl">
          {timeLeft}
        </p>
      </div>
    </div>
  );
};

type StartButtonComponentProps = {
  start: () => void;
};

const StartButtonComponent = ({ start }: StartButtonComponentProps) => {
  const isOnline = useNavigatorOnline();

  if (!isOnline) {
    return (
      <div className="relative outline outline-4 outline-white rounded-full h-16 w-16 p-0.5">
        <button
          type="button"
          className="w-full h-full bg-gray-600 border-none rounded-full p-0 flex items-center justify-center"
          onClick={start}
          onContextMenu={(e) => e.preventDefault()}
        >
          <WifiOff width={24} height={24} color="#fff" outline="#fff" />
        </button>
        <div className="absolute -top-24 left-1/2 -translate-x-1/2">
          <div className="motion-safe:animate-bounce bg-white rounded-xl py-4 px-6 after:rounded-none after:z-50 after:content-[''] after:w-0 after:h-0 after:absolute after:border-l-[16px] after:border-l-transparent after:border-r-[16px] after:border-r-transparent after:border-t-[16px] after:border-t-white after:-bottom-[15px] after:left-1/2 after:-translate-x-1/2">
            <p className="text-theme-black w-64 max-w-64">
              <FormattedMessage
                id="subtitle.recorder.offline"
                defaultMessage={`Você está offline. Por favor, verifique sua conexão.`}
              />
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative outline outline-4 outline-white rounded-full h-16 w-16 p-0.5">
      <button
        type="button"
        className="w-full h-full bg-red-600 border-none rounded-full p-0"
        onClick={start}
        onContextMenu={(e) => e.preventDefault()}
      ></button>
      <div className="absolute -top-20 left-1/2 -translate-x-1/2">
        <div className="motion-safe:animate-bounce bg-white rounded-xl py-4 px-6 after:rounded-none after:z-50 after:content-[''] after:w-0 after:h-0 after:absolute after:border-l-[16px] after:border-l-transparent after:border-r-[16px] after:border-r-transparent after:border-t-[16px] after:border-t-white after:-bottom-[15px] after:left-1/2 after:-translate-x-1/2">
          <p className="whitespace-nowrap text-theme-black">
            <FormattedMessage
              id="subtitle.recorder.startRecord"
              defaultMessage={`Clique para começar a gravar.`}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

type ReverseButtonComponentProps = {
  reverse: () => void;
};

const ReverseButtonComponent = ({ reverse }: ReverseButtonComponentProps) => {
  return (
    <div className="justify-self-end relative rounded-full h-14 w-14 mr-5">
      <button
        type="button"
        className="w-full h-full bg-gray-800 border-none rounded-full p-0 flex items-center justify-center"
        onClick={reverse}
        onContextMenu={(e) => e.preventDefault()}
      >
        <RefreshRight
          width={30}
          height={30}
          color="transparent"
          outline="#fff"
        />
      </button>
    </div>
  );
};

type StopButtonComponentProps = {
  stop: () => void;
};

const StopButtonComponent = ({ stop }: StopButtonComponentProps) => {
  return (
    <div className="relative outline outline-4 outline-white rounded-full h-16 w-16 p-0.5 flex items-center justify-center">
      <button
        type="button"
        className="w-7 h-7 bg-red-600 border-none rounded-md p-0"
        onClick={stop}
        onContextMenu={(e) => e.preventDefault()}
      ></button>
    </div>
  );
};
