import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useEventStore } from "store/useEventStore";

export default function BrowserError() {
  const { theme } = useEventStore();
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className="px-10 py-16 flex flex-col items-center">
      <h1 className="mb-9 text-theme-black">
        <FormattedMessage id="title.recorder.alert" defaultMessage="Alerta!" />
      </h1>
      <div
        className={`flex flex-col items-center py-10 px-8 gap-2 card ${theme.card}`}
      >
        <p className="text-theme-black">
          <FormattedMessage
            id="subtitle.recorder.browserNotSupported"
            defaultMessage="Seu navegador não é suportado ou a permissão não foi concedida."
          />
        </p>
        <p>
          <FormattedMessage
            id="subtitle.recorder.tryGrantPermission"
            defaultMessage="1. Atualize a página e permita o uso da câmera."
          />
        </p>
        <p className="mb-8 text-theme-black">
          <FormattedMessage
            id="subtitle.recorder.tryAnotherBrowser"
            defaultMessage="Tente usar outro navegador como {chrome} ou {safari}."
            values={{
              chrome: <span className="highlight">Chrome</span>,
              safari: <span className="highlight">Safari</span>,
            }}
          />
        </p>
        <button
          className={`py-3 px-10 ${theme.button}`}
          type="button"
          onClick={onClick}
        >
          <FormattedMessage id="button.recorder.back" defaultMessage="Voltar" />
        </button>
      </div>
    </div>
  );
}
