import { useLogEvent } from "hooks/useLogEvent";
import { useMemo, useRef } from "react";
import { useEventStore } from "store/useEventStore";
import * as SpacesService from "libs/digitalocean/SpacesService";
import CdtAppPublicApiClient from "cdt-app-public-api/dist";

export const useUploader = () => {
  const { event } = useEventStore();
  const fileIdRef = useRef<string | null>(null);
  const { logStartUploadFile, logFinishUploadFile, logUploadFileError } =
    useLogEvent();

  const baseFileName = useMemo(() => {
    if (!event) return "";
    const randomCode = Math.random().toString(36).substring(2, 8);
    const now = new Date().toISOString();
    const base = `${event?.title}-[${event?.code}]-[${now}]_${randomCode}`;
    return base;
  }, [event]);

  const uploadFile = async (
    file: File,
    setUploadProgress: React.Dispatch<React.SetStateAction<number>>
  ) => {
    try {
      const fileExt = file.name.split(".").pop();
      const fileName = `${baseFileName}.${fileExt}`;

      logStartUploadFile({
        fileName: fileName,
      });

      const fileStoragePath = `uploads/${event?.id}_${event?.code}/${fileName}`;

      await SpacesService.uploadFile(file, fileStoragePath, setUploadProgress);

      const uploadData = {
        eventId: event?.id,
        eventCode: event?.code,
        fileStoragePath,
        fileName,
        fileSize: file.size,
        fileType: file.type,
        fileExt,
      };

      const cdtAppPublicApiClient = new CdtAppPublicApiClient();
      const response = await cdtAppPublicApiClient.createUpload(uploadData);

      if (!response) {
        throw new Error("uploadFile [can't create upload record]");
      }

      fileIdRef.current = response.id;

      logFinishUploadFile({
        fileName,
        fileId: fileIdRef.current as string,
        fileUrl: fileStoragePath,
      });

      setUploadProgress(-1);
    } catch (error) {
      const errorMsg = `uploadFile [${JSON.stringify(error)}]`;
      logUploadFileError({ error: errorMsg });
      throw new Error(errorMsg);
    }
  };

  return {
    uploadFile,
  };
};
