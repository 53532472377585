import { FormattedMessage } from "react-intl";
import AndroidChromeSiteSettings from "assets/images/android-chrome-site-settings.png";
import AndroidChromeSitePermissions from "assets/images/android-chrome-site-permissions.png";

export default function AndroidInstructions() {
  return (
    <>
      <p className="font-century-gothic-bold">
        <FormattedMessage
          id="subtitle.recorder.permissionCameraOrMicrophoneDenied"
          defaultMessage="Não foi permitido o uso da câmera/microfone."
        />
      </p>
      <p className="">
        <FormattedMessage
          id="subtitle.recorder.tryChromeBrowser"
          defaultMessage="1. Use o navegador <code>CHROME</code>"
          values={{
            code: (text) => (
              <span className="font-century-gothic-bold">{text}</span>
            ),
          }}
        />
      </p>
      <p className="">
        <FormattedMessage
          id="subtitle.recorder.changeSiteSettingsUsingChrome"
          defaultMessage='2. Acesse as configurações do site e clique em <code>"Permissões"</code>'
          values={{
            code: (text) => (
              <span className="font-century-gothic-bold">{text}</span>
            ),
          }}
        />
      </p>

      <img src={AndroidChromeSiteSettings} alt="Configurações da página" />

      <p className="">
        <FormattedMessage
          id="subtitle.recorder.grantCameraAndMicrophonePermissions"
          defaultMessage="3. <code>Permita o uso</code> da câmera e microfone"
          values={{
            code: (text) => (
              <span className="font-century-gothic-bold">{text}</span>
            ),
          }}
        />
      </p>

      <img src={AndroidChromeSitePermissions} alt="Permissões da página" />

      <p className="">
        <FormattedMessage
          id="subtitle.recorder.refreshPage"
          defaultMessage="4. Atualize a página."
        />
      </p>
    </>
  );
}
