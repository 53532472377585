import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEventStore } from "store/useEventStore";
import { useUploadHistoryStore } from "store/useUploadHistoryStore";
import BigButton from "components/BigButton";
import { Attention, Check, Close, Image, LogoIcon, Youtube } from "assets/svg";
import { FormattedMessage, useIntl } from "react-intl";
import { filesize } from "filesize";
import { useUploader } from "services/file";
import ProgressBar from "components/ProgressBar";
import FilePreview from "components/FilePreview";
import { parseLanguage, selectLanguage, shareLink } from "utils/utils";
import LanguageButton from "components/theme/LanguageButton";
import ShareButton from "components/theme/ShareButton";
import { useLogEvent } from "hooks/useLogEvent";

const IMAGE_SIZE_LIMIT = 20000000;
const PHOTOS_MAX_LIMIT = 50;
const VIDEO_SIZE_LIMIT = 200000000;

const validateFile = (file: File) => {
  const isVideo = file.type.includes("video");
  const isSizeValid =
    file.size <= (isVideo ? VIDEO_SIZE_LIMIT : IMAGE_SIZE_LIMIT);

  return isSizeValid;
};

const UploadFile = ({
  file,
  onFileStatusChange,
  status,
  hideProgressBar = false,
  errorMessage,
}: {
  file: File;
  onFileStatusChange?: Function;
  status?: String;
  hideProgressBar?: boolean;
  errorMessage?: React.ReactNode;
}) => {
  const { uploadFile } = useUploader();
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const { theme } = useEventStore();

  const fileProps = useMemo(() => {
    const humanSize = filesize(file.size);

    return { humanSize };
  }, [file]);

  useEffect(() => {
    const uploadFileAsync = async () => {
      setUploading(true);

      try {
        await uploadFile(file, (progress) => setProgress(progress));
      } catch (error) {
        console.error(error);

        if (onFileStatusChange) {
          onFileStatusChange(file, "fail");
        }
      }
    };

    if (status === "uploading" && !uploading) {
      uploadFileAsync();
    }
  }, [status, file, uploadFile, onFileStatusChange, setUploading, uploading]);

  useEffect(() => {
    if (progress === -1 && onFileStatusChange) {
      onFileStatusChange(file, "completed");
    }
  }, [progress, file, onFileStatusChange]);

  if (status === "completed") {
    return (
      <div className={`w-full flex p-2 mb-1 card ${theme.card}`}>
        <div className="flex-none relative w-16 h-16 overflow-hidden">
          <FilePreview file={file} />
        </div>

        <div className="w-full flex flex-col items-start justify-start px-2 pt-2">
          <span className="line-clamp-1 break-all">{file.name}</span>
          <span className="text-xs text-slate-600 font-century-gothic-bold">
            {fileProps.humanSize}
          </span>

          {!hideProgressBar && (
            <div className="w-full h-5 flex items-end">
              <ProgressBar progress={100} />
            </div>
          )}
        </div>

        <div className="w-20 flex justify-center items-center">
          <Check width={24} height={24} color="#FFF" outline="#15803d" />
        </div>
      </div>
    );
  }

  if (status === "fail") {
    return (
      <div className={`w-full p-2 mb-1 card ${theme.card}`}>
        <div className="w-full flex">
          <div className="flex-none relative w-16 h-16 overflow-hidden">
            <FilePreview file={file} />
          </div>

          <div className="w-full flex flex-grow flex-col items-start justify-start px-2 pt-2">
            <span className="line-clamp-1 break-all">{file.name}</span>
            <span className="text-xs text-slate-600 font-century-gothic-bold">
              {fileProps.humanSize}
            </span>
          </div>

          <div className="px-1 flex justify-center items-center">
            <Attention width={24} height={24} color="#FFF" outline="#D60000" />
          </div>
        </div>

        <p className="text-red-600 text-xs text-left pt-2 font-century-gothic-bold">
          {errorMessage}
        </p>
      </div>
    );
  }

  return (
    <div className={`w-full flex p-2 mb-1 card ${theme.card}`}>
      <div className="flex-none relative w-16 h-16 overflow-hidden">
        <FilePreview file={file} />
      </div>

      <div className="w-full flex flex-col items-start justify-start px-2 pt-2">
        <span className="line-clamp-1 break-all">{file.name}</span>
        <span className="text-xs text-slate-600 font-century-gothic-bold">
          {fileProps.humanSize}
        </span>

        {!hideProgressBar && (
          <div className="w-full h-5 flex items-end">
            <ProgressBar
              progress={progress < 0 ? 100 : progress}
              color={theme.progressBar}
            />
          </div>
        )}
      </div>

      <div className="w-20 flex justify-center items-center"></div>
    </div>
  );
};

const FileItem = ({ file, onRemove }: { file: File; onRemove: Function }) => {
  const { theme } = useEventStore();

  const fileProps = useMemo(() => {
    const humanSize = filesize(file.size);
    return { humanSize };
  }, [file]);

  return (
    <div className={`w-full flex p-2 mb-1 card ${theme.card}`}>
      <div className="flex-none relative w-16 h-16 overflow-hidden">
        <FilePreview file={file} />
      </div>

      <div className="w-full flex flex-col items-start justify-start px-2 pt-2">
        <span className="line-clamp-1 break-all">{file.name}</span>
        <span className="text-xs text-slate-600 font-century-gothic-bold">
          {fileProps.humanSize}
        </span>
      </div>

      <div className="w-8 px-4 flex justify-center items-center">
        <button onClick={() => onRemove(file)}>
          <Close width={24} height={24} color="#000" outline="#000" />
        </button>
      </div>
    </div>
  );
};

const FileHistoryItem = ({ entry }: { entry: any }) => {
  const { theme } = useEventStore();
  const isVideo = entry.name.includes("mp4");

  const dateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const dateStr = new Date(entry.date).toLocaleString(
    undefined,
    dateOptions as Intl.DateTimeFormatOptions
  );

  return (
    <div className={`flex justify-between items-center card ${theme.card} p-2`}>
      <div>
        {isVideo ? (
          <Youtube width={24} height={24} color="#FFF" outline="#6C6C6C" />
        ) : (
          <Image width={24} height={24} color="#FFF" outline="#6C6C6C" />
        )}
      </div>

      <span className="flex-grow line-clamp-1 break-all px-2">
        {entry.name}
      </span>
      <span className="whitespace-nowrap text-xs font-century-gothic-bold text-stone-500">
        {dateStr}
      </span>
    </div>
  );
};

export default function Upload() {
  const { event, language, theme, publicUrl, setLanguage } = useEventStore();
  const photoInputRef = useRef<HTMLInputElement>(null);
  const videoInputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<Array<File>>([]);
  const [uploadStatus, setUploadStatus] = useState<any>({});
  const { uploadHistory, setUploadHistory } = useUploadHistoryStore();
  const { logShareButton, logUploadFileType, logPageState } = useLogEvent();
  const intl = useIntl();

  const eventHistory = useMemo(() => {
    const history = uploadHistory[event?.id as string] || [];
    return history.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  }, [uploadHistory, event]);

  const validFiles = useMemo(() => {
    return files.filter((file) => validateFile(file));
  }, [files]);

  const invalidFiles = useMemo(() => {
    return files.filter((file) => !validateFile(file));
  }, [files]);

  const uploadSuccessCount = useMemo(() => {
    return Object.values(uploadStatus).filter(
      (status) => status === "completed"
    ).length;
  }, [uploadStatus]);

  const uploadFailureCount = useMemo(() => {
    return Object.values(uploadStatus).filter((status) => status === "fail")
      .length;
  }, [uploadStatus]);

  const pageState = useMemo(() => {
    const uploadHistoryFound = Object.entries(uploadStatus).length > 0;
    const uploadFinished = Object.values(uploadStatus).every((status) => {
      return status === "completed" || status === "fail";
    });

    if (uploadHistoryFound && uploadFinished) return "completed";
    if (uploadHistoryFound && !uploadFinished) return "uploading";
    if (validFiles.length === 0 && invalidFiles.length > 0)
      return "allFilesInvalid";
    if (validFiles.length > PHOTOS_MAX_LIMIT) return "maxFilesLimit";
    if (files.length > 0) return "preview";

    return "initial";
  }, [files, uploadStatus, invalidFiles, validFiles]);

  const uploadHistoryFound = useMemo(() => {
    return Object.entries(eventHistory).length > 0;
  }, [eventHistory]);

  const handlePhotoButton = (event: any) => {
    logUploadFileType("photo");

    photoInputRef.current?.click();
  };

  const handleVideoButton = (event: any) => {
    logUploadFileType("video");

    videoInputRef.current?.click();
  };

  const handleFilesSelected = useCallback(async (event: any) => {
    const filesArray = Array.from(event.target.files);

    if (filesArray) {
      setFiles(filesArray as File[]);
      event.target.value = "";
    }
  }, []);

  const handleRemove = (file: File) => {
    setFiles((files) => {
      return files.filter((f) => f !== file);
    });
  };

  const handleBackButton = (event: any) => {
    setFiles([]);
    setUploadStatus({});
  };

  const updateFileStatus = useCallback(
    (file: File, status: string) => {
      setUploadStatus((prev: any) => ({ ...prev, [file.name]: status }));

      if (status === "completed") {
        const newHistoryEntry = {
          name: file.name,
        };

        setUploadHistory(event?.id as string, newHistoryEntry);
      }
    },
    [setUploadStatus, setUploadHistory, event]
  );

  const handleUploadFiles = async (event: any) => {
    setUploadStatus(
      validFiles.reduce((acc, file) => {
        return { ...acc, [file.name]: "uploading" };
      }, {})
    );
  };

  const onChangeLanguage = useCallback(() => {
    const nextLanguage = selectLanguage(event?.languages, language);
    setLanguage(nextLanguage as string);
  }, [language, setLanguage, event?.languages]);

  const languageLabel = useMemo(() => {
    return parseLanguage(event?.languages, language) as string;
  }, [event?.languages, language]);

  const onShareEventLink = useCallback(() => {
    const text = intl.formatMessage({
      id: "message.uploader.shareMessage",
      defaultMessage: "Compartilhe seus momentos favoritos da festa!",
    });
    shareLink(publicUrl, text, logShareButton);
  }, [intl, publicUrl, logShareButton]);

  const onLandingPageClick = useCallback(() => {
    const capsulaLadingPage = "https://galeria.capsuladotempo.com";
    window.open(capsulaLadingPage, "_blank");
  }, []);

  useEffect(() => {
    const pageStateTitle =
      "upload_" + pageState.replace(/([A-Z])/g, "_$1").toLowerCase();
    logPageState(pageStateTitle);
  }, [pageState, logPageState]);

  if (pageState === "preview") {
    return (
      <div className="template-container px-3 pt-8 pb-10 flex flex-col items-center">
        <h1 className="text-center text-theme-black">
          <FormattedMessage
            id="title.uploader.upload"
            defaultMessage="Upload"
          />
        </h1>

        <div className="w-full">
          <div className="mb-8">
            {validFiles.length > 0 && (
              <div className="bg-green-200 p-3 card rounded">
                <h6 className="text-green-700 mb-4 font-century-gothic-bold">
                  <FormattedMessage
                    id="subtitle.uploader.filesReadyToUpload"
                    defaultMessage="Arquivos prontos para o envio:"
                  />
                </h6>

                {validFiles.map((file, index) => (
                  <FileItem key={index} file={file} onRemove={handleRemove} />
                ))}
              </div>
            )}
          </div>

          {invalidFiles.length > 0 && (
            <div className="bg-red-200 mt-8 p-3 card rounded">
              <h6 className="text-red-600 mb-4 font-century-gothic-bold">
                <FormattedMessage
                  id="subtitle.uploader.filesWithError"
                  values={{
                    code: (text) => (
                      <span className="underline decoration-solid">{text}</span>
                    ),
                  }}
                  defaultMessage="Arquivos que <code>não serão enviados</code>:"
                />
              </h6>

              <div className="space-y-1">
                {invalidFiles.map((file, index) => (
                  <UploadFile
                    key={index}
                    file={file}
                    status={"fail"}
                    errorMessage={
                      <FormattedMessage
                        id="message.uploader.fileSizeError"
                        defaultMessage="Arquivo excede tamanho permitido"
                      />
                    }
                  />
                ))}
              </div>
            </div>
          )}

          <div className="flex mt-12 justify-between">
            <button
              type="button"
              className={`
              font-century-gothic-bold text-white py-3 w-[120px]  flex whitespace-nowrap justify-center items-center rounded-xl ${theme.button}
            `}
              onClick={handleBackButton}
            >
              <FormattedMessage
                id="button.uploader.back"
                defaultMessage="Voltar"
              />
            </button>

            <button
              type="button"
              className={`
              font-century-gothic-bold text-white py-3 w-[120px] whitespace-nowrap justify-center items-center rounded-xl ${theme.button}
            `}
              onClick={handleUploadFiles}
            >
              <FormattedMessage
                id="button.uploader.send"
                defaultMessage="Enviar"
              />
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (pageState === "maxFilesLimit") {
    return (
      <div className="template-container w-full h-full px-8 pt-8 pt-10 flex flex-col items-center">
        <h1 className="text-center text-theme-black">
          <FormattedMessage id="title.uploader.alert" defaultMessage="Alerta" />
        </h1>

        <div className={`card ${theme.card} px-8 py-10`}>
          <p className="text-center">
            <FormattedMessage
              id="message.uploader.maxFilesLimitError"
              values={{
                code: (text) => (
                  <span className="font-century-gothic-bold">{text}</span>
                ),
              }}
              defaultMessage="Selecione no <code>máximo 50 fotos</code>."
            />
          </p>
        </div>

        <div className="mt-64 text-center">
          <button
            type="button"
            className={`font-century-gothic-bold text-white py-3 w-[120px] flex whitespace-nowrap justify-center items-center rounded-xl ${theme.button}`}
            onClick={handleBackButton}
          >
            <FormattedMessage
              id="button.uploader.back"
              defaultMessage="Voltar"
            />
          </button>
        </div>
      </div>
    );
  }

  if (pageState === "allFilesInvalid") {
    return (
      <div className="template-container px-3 pt-8 pb-10 flex flex-col items-center">
        <h1 className="text-center ext-theme-black">
          <FormattedMessage
            id="title.uploader.alert"
            defaultMessage="Alerta!"
          />
        </h1>

        <div className="px-9">
          <div className={`card ${theme.card} px-4 py-12`}>
            <p>
              <FormattedMessage
                id="message.uploader.notFoundFilesToUpload"
                values={{
                  code: (text) => (
                    <span className="font-century-gothic-bold">{text}</span>
                  ),
                }}
                defaultMessage="<code>Nenhum arquivo pode ser enviado</code>."
              />
            </p>

            <p>
              <FormattedMessage
                id="message.uploader.checkFileSizeAndTryAgain"
                values={{
                  code: (text) => (
                    <span className="font-century-gothic-bold">{text}</span>
                  ),
                }}
                defaultMessage="Por favor, verifique o <code> tamanho do arquivo</code> e tente novamente."
              />
            </p>
          </div>
        </div>

        <div className="w-full bg-red-200 mt-8 p-3 card rounded">
          <h6 className="text-red-600 mb-4 font-century-gothic-bold">
            <FormattedMessage
              id="subtitle.uploader.filesWithError"
              values={{
                code: (text) => (
                  <span className="underline decoration-solid">{text}</span>
                ),
              }}
              defaultMessage="Arquivos que <code>não serão enviados</code>:"
            />
          </h6>

          <div className="space-y-1">
            {invalidFiles.map((file, index) => (
              <UploadFile
                key={index}
                file={file}
                status={"fail"}
                errorMessage={
                  <FormattedMessage
                    id="message.uploader.fileSizeError"
                    defaultMessage="Arquivo excede tamanho permitido"
                  />
                }
              />
            ))}
          </div>
        </div>

        <div className="flex mt-12 justify-center">
          <button
            type="button"
            className={`
              font-century-gothic-bold text-white py-3 w-[120px] flex whitespace-nowrap justify-center items-center rounded-xl ${theme.button}
            `}
            onClick={handleBackButton}
          >
            <FormattedMessage
              id="button.uploader.back"
              defaultMessage="Voltar"
            />
          </button>
        </div>
      </div>
    );
  }

  if (pageState === "uploading") {
    return (
      <div className="template-container px-2 pt-8 pb-10 flex flex-col items-center">
        <h1 className="text-center text-theme-black">
          <FormattedMessage
            id="title.uploader.uploading"
            defaultMessage="Enviando"
          />
        </h1>

        <div className={`card ${theme.card} w-5/6 py-10 px-10`}>
          <p className="text-center font-century-gothic-bold">
            <FormattedMessage
              id="message.uploader.uploading"
              defaultMessage="Por favor, não atualize ou feche esta página."
            />
          </p>

          {files.length > 1 && (
            <p className="mt-5 font-century-gothic-bold text-gray-400 text-sm text-center">
              <FormattedMessage
                id="message.uploader.uploadVerySlow"
                defaultMessage="Caso o envio esteja lento, selecione poucas fotos por vez"
              />
            </p>
          )}
        </div>

        <div className="w-full mt-6 px-3 space-y-1">
          {validFiles.map((file, index) => (
            <UploadFile
              key={index}
              file={file}
              onFileStatusChange={updateFileStatus}
              status={uploadStatus[file.name]}
              errorMessage={
                uploadStatus[file.name] === "fail" && (
                  <FormattedMessage
                    id="message.uploader.uploadError"
                    defaultMessage="Falha ao enviar."
                  />
                )
              }
            />
          ))}
        </div>
      </div>
    );
  }

  if (pageState === "completed") {
    return (
      <div className="template-container px-2 pt-8 pb-10 flex flex-col items-center">
        <h1 className="text-center text-theme-black">
          {uploadFailureCount > 0 && uploadSuccessCount === 0 ? (
            <FormattedMessage
              id="title.uploader.uploadFailure"
              defaultMessage="Falha no envio"
            />
          ) : (
            <FormattedMessage
              id="title.uploader.completed"
              defaultMessage="Concluído"
            />
          )}
        </h1>

        <div
          className={`card ${theme.card} w-5/6 py-10 flex flex-col items-center`}
        >
          {uploadFailureCount === 0 && (
            <>
              <p className="text-center">
                <FormattedMessage
                  id="message.uploader.uploadComplete"
                  defaultMessage="Upload concluído"
                />
              </p>

              <p className="text-center font-century-gothic-bold">
                <FormattedMessage
                  id="message.uploader.allFilesUploaded"
                  defaultMessage="Todos os arquivos foram enviados"
                />
              </p>
            </>
          )}

          {uploadSuccessCount > 0 && uploadFailureCount > 0 && (
            <>
              <p className="text-center">
                <FormattedMessage
                  id="message.uploader.uploadComplete"
                  defaultMessage="Upload concluído"
                />
              </p>

              <p className="text-center font-century-gothic-bold pb-6">
                <FormattedMessage
                  id="message.uploader.nFilesUploaded"
                  values={{
                    n: uploadSuccessCount,
                  }}
                  defaultMessage="{n} arquivo(s) enviado(s)"
                />
              </p>

              <p className="text-center text-red-600 font-century-gothic-bold">
                <FormattedMessage
                  id="message.uploader.nFilesNotUploaded"
                  values={{
                    n: uploadFailureCount,
                  }}
                  defaultMessage="{n} arquivo(s) não foram enviado(s)"
                />
              </p>

              <p className="text-center text-red-600 font-century-gothic-bold">
                <FormattedMessage
                  id="message.uploader.checkYourConnectionAndTryAgain"
                  values={{
                    n: uploadSuccessCount,
                  }}
                  defaultMessage="Verifique sua conexão e tente novamente"
                />
              </p>
            </>
          )}

          {uploadSuccessCount === 0 && uploadFailureCount > 0 && (
            <>
              <p className="text-center text-red-600 font-century-gothic-bold">
                <FormattedMessage
                  id="message.uploader.noFilesUploaded"
                  values={{
                    n: uploadSuccessCount,
                  }}
                  defaultMessage="Nenhum arquivo foi enviado"
                />
              </p>

              <p className="text-center text-red-600 font-century-gothic-bold">
                <FormattedMessage
                  id="message.uploader.checkYourConnectionAndTryAgain"
                  values={{
                    n: uploadSuccessCount,
                  }}
                  defaultMessage="Verifique sua conexão e tente novamente"
                />
              </p>
            </>
          )}

          <button
            type="button"
            className={`
              font-century-gothic-bold text-white mt-6 py-3 w-[120px] whitespace-nowrap justify-center items-center rounded-xl ${theme.button}
            `}
            onClick={handleBackButton}
          >
            <FormattedMessage id="button.uploader.ok" defaultMessage="OK!" />
          </button>
        </div>

        <div className="w-full mt-6 space-y-1 px-3">
          {validFiles.map((file, index) => (
            <UploadFile
              key={index}
              file={file}
              onFileStatusChange={updateFileStatus}
              status={uploadStatus[file.name]}
              hideProgressBar={true}
              errorMessage={
                uploadStatus[file.name] === "fail" && (
                  <FormattedMessage
                    id="message.uploader.uploadError"
                    defaultMessage="Falha ao enviar."
                  />
                )
              }
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="template-container py-16 flex flex-col items-center">
      <h1 className="text-center text-theme-black">
        <FormattedMessage
          id="title.uploader.shareMoments"
          defaultMessage="Compartilhe momentos"
        />
      </h1>

      <div className="px-10">
        <div
          className={`flex flex-col items-center pt-10 pb-8 px-6 card ${theme.card}`}
        >
          <p className="text-theme-black">
            <FormattedMessage
              id="message.uploader.uploadMaxFilesLimit"
              values={{
                code: (text) => (
                  <span className="font-century-gothic-bold">{text}</span>
                ),
              }}
              defaultMessage="Envie até <code>50 fotos ou 1 vídeo</code> por vez."
            />
          </p>

          <p className="text-theme-black pt-6">
            <FormattedMessage
              id="message.uploader.uploadLimit"
              values={{
                code: (text) => (
                  <span className="font-century-gothic-bold">{text}</span>
                ),
              }}
              defaultMessage="Limite máximo:"
            />
          </p>

          <p className="text-theme-black">
            <FormattedMessage
              id="message.uploader.photoSizeLimit"
              values={{
                code: (text) => (
                  <span className="font-century-gothic-bold">{text}</span>
                ),
              }}
              defaultMessage="<code>20MB</code> por foto."
            />
          </p>

          <p className="text-theme-black pb-6">
            <FormattedMessage
              id="message.uploader.videoSizeLimit"
              values={{
                code: (text) => (
                  <span className="font-century-gothic-bold">{text}</span>
                ),
              }}
              defaultMessage="<code>200MB</code> por vídeo."
            />
          </p>

          <div className="flex items-between">
            <BigButton
              className={`${theme.button} mr-2.5`}
              label={
                <FormattedMessage
                  id="button.uploader.send_photos"
                  defaultMessage="Enviar fotos"
                />
              }
              icon={
                <Image
                  width={48}
                  height={48}
                  color={theme.logo}
                  outline="#FFFFFF"
                />
              }
              onClick={handlePhotoButton}
            />

            <BigButton
              className={theme.button}
              label={
                <FormattedMessage
                  id="button.uploader.send_video"
                  defaultMessage="Enviar vídeo"
                />
              }
              icon={
                <Youtube
                  width={48}
                  height={48}
                  color={theme.logo}
                  outline="#FFFFFF"
                />
              }
              onClick={handleVideoButton}
            />
          </div>

          <p className="mt-8 font-century-gothic-bold text-gray-400 text-sm text-center">
            <FormattedMessage
              id="message.uploader.uploadTip"
              defaultMessage="Para manter a qualidade do arquivo, faça o upload de arquivos da galeria do seu aparelho."
            />
          </p>
        </div>
      </div>

      <form>
        <input
          ref={photoInputRef}
          type="file"
          name="photos"
          multiple
          accept="image/*"
          className="hidden"
          onChange={handleFilesSelected}
        />

        <input
          ref={videoInputRef}
          type="file"
          name="video"
          accept="video/*"
          className="hidden"
          onChange={handleFilesSelected}
        />
      </form>

      {uploadHistoryFound && (
        <div className="w-full px-3 mt-8">
          <div className="px-2">
            <h4 className="text-2xl text-theme-black mx-2 my-4">
              <FormattedMessage
                id="subtitle.uploader.uploadHistory"
                defaultMessage="Envios concluídos"
              />
            </h4>
          </div>

          <div className="space-y-1">
            {eventHistory.map((entry, i) => (
              <FileHistoryItem key={i} entry={entry} />
            ))}
          </div>
        </div>
      )}

      <div className="w-full h-full pt-6 px-4 flex justify-start">
        <div>
          <LanguageButton label={languageLabel} onChange={onChangeLanguage} />
        </div>
        <div className="pl-2">
          <ShareButton onShare={onShareEventLink} />
        </div>

        <div className="pl-2">
          <button
            onClick={onLandingPageClick}
            type="button"
            className="rounded-full bg-transparent border border-primary-rose text-primary-rose p-2.5 h-10 w-10 flex justify-center items-center"
          >
            <LogoIcon
              width={24}
              height={24}
              outline="#F76C6F"
              color="#F76C6F"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
