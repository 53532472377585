import heic2any from "heic2any";
import { useEffect, useMemo, useState } from "react";
import { Image } from "../../src/assets/svg/index";

const FilePreview = ({ file }: { file: File }) => {
  const [src, setSrc] = useState<string | undefined>(undefined);

  const fileProps = useMemo(() => {
    const isVideo = file.type.startsWith("video/");
    const extension = file.name.split(".").pop();

    return { isVideo, extension };
  }, [file]);

  useEffect(() => {
    const generateSrc = async () => {
      if (fileProps.extension === "heic") {
        const convertedFile = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.1,
        });

        setSrc(URL.createObjectURL(convertedFile as Blob));
      } else {
        setSrc(URL.createObjectURL(file));
      }
    };

    generateSrc();
  }, [file, fileProps]);

  if (!src) {
    return (
      <div className="w-full h-full flex justify-center align-center">
        <Image width={64} height={64} color={"#FFF"} outline={"#78716c"} />;
      </div>
    );
  }

  if (fileProps.isVideo) {
    return (
      <video className="absolute w-full h-full object-cover">
        <source src={src} type="video/mp4" />
      </video>
    );
  }

  return (
    <img
      src={src}
      alt={file.name}
      className="absolute w-full h-full object-cover"
    />
  );
};

export default FilePreview;
