import React, { useMemo } from "react";

import { QRCodeSVG } from "qrcode.react";

type Props = {
  code?: string;
  size?: number;
};

export default function QRCode({ code, size = 120 }: Props) {
  const qrCodeLink = useMemo(() => {
    return `https://evento.capsuladotempo.com/?event=${code}`;
  }, [code]);

  return (
    <div>
      <div className="mx-auto max-w-[150px] flex flex-col items-center justify-center">
        <QRCodeSVG
          className="scaling-svg"
          value={qrCodeLink}
          size={size}
          bgColor={"transparent"}
          fgColor={"#000000"}
          level={"L"}
          includeMargin={false}
        />
      </div>
    </div>
  );
}
